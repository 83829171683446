import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ResumeComponent } from './admin/resume/resume.component';
import { DocumentComponent } from './document/document.component';
import { AdminComponent } from './admin/admin.component';
import { AdminDetailsComponent } from './admin/admin-details/admin-details.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login/login.component';

const appRoutes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'document', component: DocumentComponent },
  { path: 'login', component: LoginComponent },
  { 
    path: 'admin', 
    component: AdminComponent, 
    canActivate: [AuthGuard],
    children: [
      {path: 'admin-details', component: AdminDetailsComponent, canActivate: [AuthGuard]},
      {path: 'resume', component: ResumeComponent, canActivate: [AuthGuard]},
    ]
  },
  { path: '**', redirectTo: 'about'}
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot (
      appRoutes,
      {enableTracing: false}
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
