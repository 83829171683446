import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.accessToken) {            
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.accessToken}`
                }
            });

            request = request.clone({
                headers: request.headers.set( 
                    'Access-Control-Allow-Credentials', 'true'
                )
            });

            request = request.clone({
                headers: request.headers.set( 
                    'Access-Control-Allow-Origin', '*'
                )
            });

            request = request.clone({
                headers: request.headers.set( 
                    'Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
                )
            });

            request = request.clone({
                headers: request.headers.set( 
                    'Content-Type', 'application/json'
                )
            });

            request = request.clone({
                headers: request.headers.set( 
                    'Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
                )
            });
        }
        return next.handle(request);
    }
}