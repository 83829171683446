import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CurriculumService } from './_services/curriculum.service';
import { AboutComponent } from './about/about.component';
import { AppRoutingModule } from './app-routing.module';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PagetitleService } from './_services/pagetitle.service';
import { ResumeComponent } from './admin/resume/resume.component';
import { DocumentComponent } from './document/document.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EducationComponent } from './education/education.component';
import { TechnicalSkillComponent } from './technical-skill/technical-skill.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { LanguagesComponent } from './languages/languages.component';
import { AdminComponent } from './admin/admin.component';
import { AdminDetailsComponent } from './admin/admin-details/admin-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService } from './_services/authentication.service';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    PortfolioComponent,
    ResumeComponent,
    DocumentComponent,
    EducationComponent,
    TechnicalSkillComponent,
    AboutMeComponent,
    LanguagesComponent,
    AdminComponent,
    AdminDetailsComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    CurriculumService, 
    PagetitleService, 
    AuthenticationService],
  bootstrap: [AppComponent]
})
export class AppModule {

}
