import { Component, OnInit } from '@angular/core';
import { faAddressBook } from '@fortawesome/free-regular-svg-icons';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { PagetitleService } from './_services/pagetitle.service';
import { CurriculumService } from './_services/curriculum.service';
import { AuthenticationService } from './_services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  faAddressCard = faAddressCard;
  faAddressBook = faAddressBook;
  faHandshake = faHandshake;
  faLinkedin = faLinkedin;

  firstname: string;
  lastname: string;

  constructor(
    public pageTitle: PagetitleService,
    public authenticationService: AuthenticationService, 
    public cv: CurriculumService) {
  }

  ngOnInit() {
    this.cv.getCurriculum(this.authenticationService.getUserId()).subscribe(curriculum => {
      this.firstname = curriculum['firstName'];
      this.lastname = curriculum['lastName'];
    });
  }
}
