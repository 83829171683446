import { Component, OnInit } from '@angular/core';
import { CurriculumService } from '../_services/curriculum.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Portfolio } from '../_models/portfolio';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  
	public curriculum;
	public education;
	public experience;
	public portfolio: Portfolio;
	public bio: string;

	constructor(public cv: CurriculumService, 
				private authoService: AuthenticationService) {

	}

	ngOnInit() {
	this.cv.getCurriculum(this.authoService.getUserId()).subscribe(cur => {
		this.curriculum = cur;
		this.bio = this.curriculum['bio'];
		this.education = this.curriculum['education'].reverse();
		this.experience = this.curriculum['experience'].reverse();
		this.curriculum['portfolio'];
		
		let incommingData: Portfolio = JSON.parse(JSON.stringify(this.curriculum['portfolio']));
		this.portfolio = incommingData;
		});
	}
	
	public getUrl(imageUrl: string): string {
		return 'https://drive.google.com/uc?export=view&id=' + imageUrl;
	}

    /*
    this.cv.getAuthorization().subscribe(token => {
      this.storedToken = token["accessToken"];
    });*/
}
