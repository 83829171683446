import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { CurriculumService } from '../_services/curriculum.service';
import { SingleInfo } from '../_models/singleinfo';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  currentUser: User;
  
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private cv: CurriculumService,
    private formBuilder: FormBuilder,
		private spinner: NgxSpinnerService) { 
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      bio: ['', Validators.required],
      birthdate: ['', Validators.required]
    });

    this.refreshData();
  }

  private refreshData() {
    this.cv.getCurriculum(this.authenticationService.getUserId()).subscribe(curriculum => {
      this.f.firstname.setValue(curriculum['firstName']);
      this.f.lastname.setValue(curriculum['lastName']);
      this.f.bio.setValue(curriculum['bio']);
      let purgedDate = curriculum['birthDate'];
      purgedDate = purgedDate.substring(0, purgedDate.indexOf('T'));
      this.f.birthdate.setValue(purgedDate);
    });
  }

  get f() { return this.loginForm.controls; }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.spinner.show()
    const singleInfo = new SingleInfo();
    singleInfo.firstName = this.f.firstname.value;
    singleInfo.lastName = this.f.lastname.value;
    singleInfo.bio = this.f.bio.value;

    this.cv.updateSingleInfo(
      singleInfo, 
      this.authenticationService.getUserId()).subscribe(
        response => {
          this.spinner.hide()
          this.refreshData();
        },
        error => {
          this.spinner.hide()
        });
  }
}
