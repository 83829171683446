import { Component, OnInit } from '@angular/core';
import { CurriculumService } from '../../_services/curriculum.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import { Portfolio } from '../../_models/portfolio';
import { Project } from '../../_models/project';
import { PortfolioManager } from '../../_models/portfolioManager';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {

	resumeForm: FormGroup;
	submitted = false;
	currentUser: User;
  
	portfolioManager: PortfolioManager = new PortfolioManager();
  
	constructor(
	private authenticationService: AuthenticationService,
	private cv: CurriculumService,
	private formBuilder: FormBuilder,
	private spinner: NgxSpinnerService) {
		this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

		this.resumeForm = this.formBuilder.group({
			projectname: ['', Validators.required],
			description: ['', Validators.required],
			fichaURL: ['', Validators.required],
		});
	}

	ngOnInit() {
		this.refreshData();
	}

	initPhone() {
		return this.formBuilder.control('');
	}

	private addItem(item) {
		this.portfolioManager.portfolio.project.push(item);
	}

	private removeItem(id: string) {
		let index = -1;
		for (var i = 0; i < this.portfolioManager.portfolio.project.length; i++) {
			if (this.portfolioManager.portfolio.project[i]._id === id) {
				index = i;
				break;
			}
		}

		if (index !== -1) {
			this.portfolioManager.portfolio.project.splice(index, 1);
		}
	}

	get f() { return this.resumeForm.controls; }

	private refreshData() {
		this.cv.getCurriculum(this.authenticationService.getUserId()).subscribe(curriculum => {
			if (curriculum['portfolio']) {
				let incommingData: Portfolio = JSON.parse(JSON.stringify(curriculum['portfolio']));
				this.portfolioManager.portfolio = incommingData;
			} else {
				this.portfolioManager = new PortfolioManager()
				this.portfolioManager.portfolio = new Portfolio();
				this.portfolioManager.portfolio.project = new Array();
			}
		});
	}

	public addProject() {
		this.submitted = true;
		if (this.resumeForm.invalid) {
			return;
		}

		let subItem = new Project()
		subItem.description = this.f.description.value
		subItem.name = this.f.projectname.value
		subItem.fichaURL = this.f.fichaURL.value

		this.addItem(subItem);

		this.spinner.show()
		this.cv.updatePorfolio(this.portfolioManager, this.authenticationService.getUserId()).subscribe(
			data => {
				this.spinner.hide()
				this.refreshData();
				this.cleanUp();
			},
			error => {
				this.spinner.hide()
				this.refreshData();
				this.cleanUp();
			}
		);
	}

	public deleteProject(id: string) {
		this.spinner.show();
		this.removeItem(id);
		this.cv.updatePorfolio(this.portfolioManager, this.authenticationService.getUserId()).subscribe(
			data => {
				this.refreshData();
				this.spinner.hide();
			},
			error => {
				this.refreshData();
				this.spinner.hide();
			}
		);
	}

	private cleanUp() {
		this.f.projectname.setValue('');
		this.f.description.setValue('');
		this.f.fichaURL.setValue('');
	}

}
