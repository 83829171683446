import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { CurriculumService } from '../_services/curriculum.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Portfolio } from '../_models/portfolio';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

	public projectId: string;
	public incommingData: Portfolio;
	public download;
	public newUrl;

	constructor(
		private route: ActivatedRoute, 
		private authoService: AuthenticationService,
		private cv: CurriculumService,
		public sanitizer: DomSanitizer,
		private spinner: NgxSpinnerService) {
			this.route.queryParams.subscribe(params => {
				this.projectId = '12';
				if (params['project'])
					this.projectId = params['project'];
				
				if (this.projectId !== '12') {
					this.download = false;
					this.spinner.show();
					//let url: string = 'http://drive.google.com/uc?export=preview&id='+this.projectId
					let url: string = 'https://docs.google.com/file/d/' + this.projectId + '/preview';
					this.newUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url); 
					this.download = true; 
					this.spinner.hide();
				}
			});
	}

	ngOnInit() {
		this.loadProjects();
	}

	private loadProjects() {
		this.cv.getCurriculum(this.authoService.getUserId()).subscribe(cur => {
			this.incommingData = JSON.parse(JSON.stringify(cur['portfolio']));
		});
	}

}
