import { Component, OnInit } from '@angular/core';
import { CurriculumService } from '../../_services/curriculum.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import { ExperienceData } from '../../_models/experienceData';
import { Experience } from '../../_models/experience';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-details',
  templateUrl: './admin-details.component.html',
  styleUrls: ['./admin-details.component.css']
})
export class AdminDetailsComponent implements OnInit {

  experiecenForm: FormGroup;
  submitted = false;
  currentUser: User;

  experienceData: Experience = new Experience();

  constructor(
    private authenticationService: AuthenticationService,
    private cv: CurriculumService,
    private formBuilder: FormBuilder,
	private spinner: NgxSpinnerService) {
    	this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

		this.experiecenForm = this.formBuilder.group({
			company: ['', Validators.required],
			todos: ['', Validators.required],
			position: ['', Validators.required],
			beginIn: ['', Validators.required],
			endIn: ['', Validators.required]
		});
  	}

	ngOnInit() {
		this.refreshData();
	}

	initPhone() {
		return this.formBuilder.control('');
	}

	private addItem(item) {
		this.experienceData.experience.push(item);
	}

	private removeItem(id: string) {
		let index = -1;
		for (var i = 0; i < this.experienceData.experience.length; i++) {
			if (this.experienceData.experience[i]._id === id) {
				index = i;
				break;
			}
		}

		if (index !== -1) {
			this.experienceData.experience.splice(index, 1);
		}
	}

	get f() { return this.experiecenForm.controls; }

	private refreshData() {
		this.cv.getCurriculum(this.authenticationService.getUserId()).subscribe(curriculum => {
			let incommingData: ExperienceData[] = JSON.parse(JSON.stringify(curriculum['experience']));
			this.experienceData.experience = incommingData;
		});
	}

	public addExperience() {
		this.submitted = true;
		if (this.experiecenForm.invalid) {
			return;
		}

		let item = new ExperienceData()
		item.company = this.f.company.value
		item.beginIn = this.f.beginIn.value
		item.endIn = this.f.endIn.value
		item.asA = this.f.position.value

		var lines = this.f.todos.value.split('\n');
		lines.forEach(element => {
			item.todos.push(element)
		});

		this.addItem(item)

		this.spinner.show()
		this.cv.updateExperience(this.experienceData, this.authenticationService.getUserId()).subscribe(
			data => {
				this.spinner.hide()
				this.refreshData();
				this.cleanUp();
			},
			error => {
				this.spinner.hide()
				this.refreshData();
				this.cleanUp();
			}
		);
	}

	public deleteExperience(id: string) {
		this.spinner.show()
		this.removeItem(id);
		this.cv.updateExperience(this.experienceData, this.authenticationService.getUserId()).subscribe(
			data => {
				this.refreshData();
				this.spinner.hide();
			},
			error => {
				this.refreshData();
				this.spinner.hide();
			}
		);
	}

	private cleanUp() {
		this.f.company.setValue('');
		this.f.beginIn.setValue('');
		this.f.endIn.setValue('');
		this.f.position.setValue('');
		this.f.todos.setValue('');
	}
}
