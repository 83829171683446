import { Component, OnInit } from '@angular/core';
import { PagetitleService } from '../_services/pagetitle.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  constructor(private pageTitle: PagetitleService) {
    this.pageTitle.setPageTitle('Documents');
  }

  ngOnInit() {
  }

}
