import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { SingleInfo } from '../_models/singleinfo';
import { AuthenticationService } from './authentication.service';
import { Experience } from '../_models/experience';

import { PortfolioManager } from '../_models/portfolioManager';

@Injectable()
export class CurriculumService {

	constructor(private http: HttpClient,
	private authenticationService: AuthenticationService) { }

	private createAuthorizationHeader(headers: HttpHeaders, token: string) {
		return headers.append('Authorization', 'Bearer ' + token); 
	}

	public getCurriculum(userId: string) {
		//let header = new HttpHeaders();
		//header = this.createAuthorizationHeader(header, token);
		return this.http.get('https://restfullwebsites.herokuapp.com/personal_info/' + userId);
	}

	public getProject(userId: string, projectId: string) {
		return this.http.get('https://restfullwebsites.herokuapp.com/project/' + userId, 
							{params: {'projectId': projectId}});
	}

	public updateSingleInfo(singleInfo: SingleInfo, userId: string) {
		let jsonstring = JSON.stringify(singleInfo);
		return this.http.patch('https://restfullwebsites.herokuapp.com/personal_info/' + userId, jsonstring);
	}

	public updateExperience(experienceData: Experience, userId: string) {
		let jsonstring = JSON.stringify(experienceData);
		return this.http.patch('https://restfullwebsites.herokuapp.com/personal_info/' + userId, jsonstring);
	}

	public updatePorfolio(portfolioManager: PortfolioManager, userId: string) {
		let jsonstring = JSON.stringify(portfolioManager);
		return this.http.patch('https://restfullwebsites.herokuapp.com/personal_info/' + userId, jsonstring);
	}
}
