import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-skill',
  templateUrl: './technical-skill.component.html',
  styleUrls: ['./technical-skill.component.css']
})
export class TechnicalSkillComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
