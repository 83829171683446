import { Injectable } from '@angular/core';

@Injectable()
export class PagetitleService {
  public pageTitle: string;
  constructor() {
    this.pageTitle = '';
  }

  public getPageTitle() {
    return this.pageTitle;
  }

  public setPageTitle(pageTitle: string) {
    this.pageTitle = pageTitle;
  }
}
